import { defineStore } from 'pinia'
import ClientesService from '../services/clientes.service'
const _ClientesService = new ClientesService()

export const useClientesStore = defineStore('clientesStore', {
  state: () => ({
    listado_clientes: [],
    cliente_seleccionado: ''
  }),
  getters: {
    _listado_clientes: state => state.listado_clientes,
    _cliente_seleccionado: state => state.cliente_seleccionado
  },
  actions: {
    obtenerListadoClientes () {
      _ClientesService.listarCLientes().then(({ data }) => {
          const clientes = []
          for (const cliente of data) {
              clientes.push({
                  name: cliente.CardCode + ' - ' + cliente.CardName,
                  CardCode: cliente.CardCode,
                  CardName: cliente.CardName,
                  Id: cliente.Id
              })
          }
          this.listado_clientes = clientes
      })
    }
  }
})
