import http from '@/libs/http'

const baseUrl = process.env.VUE_APP_MICROSERVICE_API_CONFIG_OV

export default class MotivosAutorizacionService {
  listarMotivos (params) {
    return http.get(`${baseUrl}/mot-autoriza/listar`, {
      params,
      headers: {
        loading: true
      }
    })
  }

  listarMotivosCliente (params) {
    return http.get(`${baseUrl}/mot-autoriza/listar_motivos_cliente`, {
      params,
      headers: {
        loading: true
      }
    })
  }

  paginate (params) {
    return http.get(`${baseUrl}/mot-autoriza/paginate`, {
      params,
      headers: {
        loading: true
      }
    })
  }

  post (body) {
    return http.post(`${baseUrl}/ov-cliente`, body, {
      headers: {
        loading: true
      }
    })
  }

  put ({ id, body }) {
    return http.put(`${baseUrl}/ov-cliente/${id}`, body, {
      headers: {
        loading: true
      }
    })
  }

  delete (id) {
    return http.delete(`${baseUrl}/ov-cliente/${id}`, {
      headers: {
        loading: true
      }
    })
  }
}
