<template>
  <section>
    <div class="p-3 bg-white mt-2">
      <div class="flex justify-between">
        <div class="flex gap-2 items-center">
          <i class="pi pi-list text-sm"></i>
          <p class="text-lg font-medium">Listado de configuración para los campos de la tabla al crear ordenes de venta</p>
        </div>
        <div class="flex gap-2">
          <Button class="rounded-md p-button-success text-sm" @click="displayModalCrear = true" icon="pi pi-plus" label="Crear condicional" />
        </div>
      </div>
      <div class="w-full flex gap-2 my-4">
        <div>
          <Dropdown
            @change="cambiarCliente(true)"
            class="border-gray-300 rounded-md text-xs"
            panelClass="text-xs"
            v-model="filtros.cliente"
            :options="listadoClientes"
            optionLabel="CardName"
            placeholder="Selecciona un cliente"
          />
          <p class="text-gray-300 text-xs italic">(Obligatorio)</p>
        </div>
        <div>
          <Dropdown
            class="border-gray-300 rounded-md text-xs"
            panelClass="text-xs"
            v-model="filtros.motivo"
            :options="listadoMotivosFiltros"
            optionLabel="Nombre"
            emptyMessage="Debes seleccionar un cliente primero"
            optionValue="Id"
            placeholder="Selecciona un motivo"
            @change="obtenerColumnas"
          />
          <p class="text-gray-300 text-xs italic">(Obligatorio)</p>
        </div>
        <div>
          <Dropdown
            class="border-gray-300 rounded-md text-xs"
            panelClass="text-xs"
            v-model="filtros.campo"
            :options="listadoColumnasFiltro"
            optionLabel="Columna.nombre_tabla"
            emptyMessage="Debes seleccionar un motivo"
            optionValue="ColName"
            placeholder="Selecciona una columna"
            :showClear="true"
          />
          <p class="text-gray-300 text-xs italic">(Opcional)</p>
        </div>
        <Button @click="obtenerConfiguraciones" class="rounded-md text-sm mb-5 pb-1" label="Buscar" />
      </div>
      <div class="my-2">
        <DataTable class="p-datatable-sm text-xs" :value="listadoConfiguraciones">
          <template #empty>
              Realiza una busqueda para empezar.
          </template>
          <Column header="Cliente">
            <template #body="{data}">
                {{ data.Cliente.CardName }}
            </template>
          </Column>
          <Column header="Motivo de autorización">
            <template #body="{data}">
                {{ data.MotivoAutoriza.Nombre }}
            </template>
          </Column>
          <Column header="Columna">
            <template #body="{data}">
                {{ data.Columna.nombre_tabla }}
            </template>
          </Column>
          <Column header="Guardar campo">
            <template #body="{data}">
                {{ data.guardar ? 'Sí' : 'No' }}
            </template>
          </Column>
          <Column header="Campo requerido">
            <template #body="{data}">
                {{ data.IsRequired ? 'Sí' : 'No' }}
            </template>
          </Column>
          <Column header="Campo oculto">
            <template #body="{data}">
                {{ data.campo_oculto ? 'Sí' : 'No' }}
            </template>
          </Column>
          <Column headerClass="w-32" field="ordenamiento" header="Ordenamiento"></Column>
          <Column headerClass="w-20" header="Editar">
            <template #body="{data}">
              <Button icon="pi pi-pencil" @click="abrirModalEditar(data)" class="p-button-rounded p-button-text" />
            </template>
          </Column>
          <Column headerClass="w-20" header="Eliminar">
            <template #body="{data}">
              <Button icon="pi pi-trash" @click="eliminarLineaConfiguracion(data)" class="p-button-rounded p-button-text p-button-danger" />
            </template>
          </Column>
      </DataTable>
      <Paginator v-model:first="offset"
        :rows="limit"
        :totalRecords="totalRecords"
        :rowsPerPageOptions="[2,10,20,30,100]"
        @page="onPage($event)"
      />
      </div>
      <Dialog :modal="true" v-model:visible="displayModalEditar"  :closable="false" :style="{width: '40vw'}">
        <template #header>
          <div class="flex justify-between w-full items-center">
            <p class="text-lg font-medium">Editar configuración</p>
            <div class="flex gap-2 text-gray-600 text-xs" v-if="dataEditarConfig.actualizado">
              <i class="pi pi-replay"></i>
              <p>Última actualización por:</p>
              <p class="font-medium">{{ dataEditarConfig.actualizado }}</p>
            </div>
          </div>
        </template>
        <div class="grid grid-cols-2 gap-2 text-xs">
          <p class="font-bold">Cliente:</p>
          <Dropdown
            class="border-gray-300 rounded-md text-xs"
            panelClass="text-xs"
            v-model="dataEditarConfig.cliente"
            :options="listadoClientes"
            optionLabel="CardName"
            placeholder="Selecciona un cliente"
            :filter="true"
            @change="cambiarCliente(false)"
            :disabled="true"
          />
          <p class="font-bold">Motivo de autorización:</p>
          <Dropdown
            class="border-gray-300 rounded-md text-xs"
            panelClass="text-xs"
            v-model="dataEditarConfig.motivo"
            :options="listadoMotivosCrear"
            optionLabel="Nombre"
            emptyMessage="Debes seleccionar un cliente primero"
            optionValue="Id"
            placeholder="Selecciona un motivo"
            @change="obtenerColumnas"
            disabled="true"
          />
          <p class="font-bold self-center">Columna:</p>
          <p class="my-1 font-medium bg-yellow-300 p-2 rounded-sm">{{ listadoConfiguraciones.find(a => a.ColName === dataEditarConfig.campo).Columna.nombre_tabla }}</p>
          <p class="font-bold">Guardar:</p>
          <InputSwitch @change="!dataEditarConfig.guardar ? dataEditarConfig.requerido = false : dataEditarConfig.requerido" v-model="dataEditarConfig.guardar" />
          <p class="font-bold">Requerido:</p>
          <InputSwitch @change="dataEditarConfig.requerido ? dataEditarConfig.guardar = true : dataEditarConfig.guardar" v-model="dataEditarConfig.requerido" />
          <p class="font-bold">Campo oculto:</p>
          <InputSwitch v-model="dataEditarConfig.oculto" />
          <p class="font-bold">Ordenamiento dentro de la tabla:</p>
        </div>
        <DataTable class="my-4 text-xs p-datatable-sm" :rowClass="rowClass" v-if="dataEditarConfig.campo" :value="listadoCamposOrdenamientoTabla" :reorderableColumns="true" @rowReorder="ordenarFilas" responsiveLayout="scroll">
          <Column :rowReorder="true" headerStyle="width: 3rem" :reorderableColumn="false" />
          <Column header="">
            <template #body="{data}">
              <p>{{ data.Columna.nombre_tabla + ' - ' + data.Columna.descripcion }}</p>
            </template>
          </Column>
          <Column header="" field="ordenamiento"></Column>
        </DataTable>
        <template #footer>
            <Button label="Cancelar" icon="pi pi-times" @click="cerrarModalEditar" class="p-button-text"/>
            <Button label="Guardar" icon="pi pi-check" @click="editarConfig" autofocus />
        </template>
      </Dialog>
      <Dialog header="Crear configuración" :modal="true" v-model:visible="displayModalCrear" :closable="false" :style="{width: '40vw'}">
        <div class="grid grid-cols-2 gap-2 text-xs">
          <p class="font-bold">Cliente:</p>
          <Dropdown
            class="border-gray-300 rounded-md text-xs"
            panelClass="text-xs"
            v-model="dataCrearConfig.cliente"
            :options="listadoClientes"
            optionLabel="CardName"
            placeholder="Selecciona un cliente"
            :filter="true"
            @change="cambiarCliente(false)"
          />
          <p class="font-bold">Motivo de autorización:</p>
          <Dropdown
            class="border-gray-300 rounded-md text-xs"
            panelClass="text-xs"
            v-model="dataCrearConfig.motivo"
            :options="listadoMotivosCrear"
            optionLabel="Nombre"
            emptyMessage="Debes seleccionar un cliente primero"
            optionValue="Id"
            placeholder="Selecciona un motivo"
            @change="obtenerColumnas"
          />
          <p class="font-bold">Columna:</p>
          <div class="flex gap-2">
            <Dropdown
              class="border-gray-300 rounded-md text-xs w-full"
              panelClass="text-xs"
              v-model="dataCrearConfig.campo"
              :options="listadoColumnas"
              optionLabel="nombre_tabla"
              optionValue="nombre"
              placeholder="Selecciona una columna"
              emptyMessage="Debes seleccionar un cliente y un motivo primero"
              :filter="true"
              @change="cambioColumna"
            >
              <template #option="slotProps">
                  <div>
                      <p>{{slotProps.option.nombre_tabla + ' - ' + slotProps.option.descripcion }}</p>
                  </div>
              </template>
            </Dropdown>
          </div>
          <p class="font-bold">Guardar:</p>
          <InputSwitch @change="!dataCrearConfig.guardar ? dataCrearConfig.requerido = false : dataCrearConfig.requerido" v-model="dataCrearConfig.guardar" />
          <p class="font-bold">Requerido:</p>
          <InputSwitch @change="dataCrearConfig.requerido ? dataCrearConfig.guardar = true : dataCrearConfig.guardar" v-model="dataCrearConfig.requerido" />
          <p class="font-bold">Campo oculto:</p>
          <InputSwitch v-model="dataCrearConfig.oculto" />
          <p class="font-bold">Ordenamiento dentro de la tabla:</p>
        </div>
        <DataTable class="my-4 text-xs p-datatable-sm" :rowClass="rowClass" v-if="dataCrearConfig.campo" :value="listadoCamposOrdenamientoTabla" :reorderableColumns="true" @rowReorder="ordenarFilas" responsiveLayout="scroll">
          <Column :rowReorder="true" headerStyle="width: 3rem" :reorderableColumn="false" />
          <Column header="">
            <template #body="{data}">
              <p>{{ data.Columna.nombre_tabla + ' - ' + data.Columna.descripcion }}</p>
            </template>
          </Column>
          <Column header="" field="ordenamiento"></Column>
        </DataTable>
        <template #footer>
            <Button label="Cancelar" icon="pi pi-times" @click="crearConfig(false)" class="p-button-text"/>
            <Button label="Guardar" icon="pi pi-check" @click="crearConfig(true)" autofocus />
        </template>
      </Dialog>
    </div>
  </section>
</template>
<script>
  import { useClientesStore } from '../../../../stores/clientes.store'
  import ConfiguracionesService from '../../../../services/configuracion.service'
  import MotivosAutorizacionService from '../../../../services/motivos_autorizacion.service'
  import { ref, onMounted, computed } from 'vue'
  import { useStore } from '@/store'
  import Swal from 'sweetalert2'
  export default {
    name: 'configuracionOv',
    setup () {
      // Servicios
      const _ConfiguracionesService = ref(new ConfiguracionesService())
      const _MotivosAutorizacionService = ref(new MotivosAutorizacionService())
      const storePersona = useStore()
      const people = storePersona.getters['auth/getPeople']
      // Stores
      const clientesStore = useClientesStore()
      // Referencias
      const listadoConfiguraciones = ref([])
      const offset = ref(0)
      const page = ref(0)
      const limit = ref(10)
      const totalRecords = ref(0)
      const displayModalEditar = ref(false)
      const displayModalCrear = ref(false)
      const listadoColumnas = ref([])
      const listadoMotivosFiltros = ref([])
      const listadoMotivosCrear = ref([])
      const listadoCamposOrdenamientoTabla = ref([])
      const listadoColumnasFiltro = ref([])
      const filtros = ref({
        cliente: '',
        motivo: '',
        campo: ''
      })
      const dataCrearConfig = ref({
        cliente: '',
        motivo: '',
        campo: '',
        requerido: false,
        ordenamiento: 0,
        guardar: false,
        oculto: false
      })
      const dataEditarConfig = ref({
        cliente: '',
        motivo: '',
        campo: '',
        requerido: false,
        ordenamiento: 0,
        guardar: false,
        oculto: false,
        actualizado: ''
      })
      // Computadas
      const params = computed(() => {
        return {
          offset: offset.value,
          page: page.value,
          limit: limit.value,
          filtros: filtros.value
        }
      })
      const listadoClientes = computed(() => clientesStore._listado_clientes)
      // Métodos
      const obtenerConfiguraciones = () => {
        const object = { ...params.value }
        const filter = { ...filtros.value }
        if (filtros.value.cliente) {
          filter.cliente = filtros.value.cliente?.CardCode
          object.filtros = filter
        }
        _ConfiguracionesService.value.listarConfiguraciones(object).then(({ data }) => {
          listadoConfiguraciones.value = data.rows
          totalRecords.value = data.count
        })
      }
      const onPage = ({ first, page, pageCount, rows }) => {
        limit.value = rows
        offset.value = first
        obtenerConfiguraciones(params.value)
      }
      const cambiarCliente = (type) => {
        const objeto = { ClienteId: '' }
        type ? objeto.ClienteId = filtros.value.cliente.Id : objeto.ClienteId = dataCrearConfig.value.cliente.Id
        _MotivosAutorizacionService.value.listarMotivosCliente(objeto).then(({ data }) => {
          if (type) {
            listadoMotivosFiltros.value = data
          } else {
            listadoMotivosCrear.value = data
            obtenerColumnas()
          }
        })
      }
      const obtenerColumnas = () => {
        if (dataCrearConfig.value.cliente && dataCrearConfig.value.motivo) {
          _ConfiguracionesService.value.listarColumnasTablaCrear({ cliente: dataCrearConfig.value.cliente.CardCode, motivo: dataCrearConfig.value.motivo }).then(({ data }) => {
            listadoColumnas.value = data.columnasCrear
            listadoCamposOrdenamientoTabla.value = data.columnasExistentes
          })
        }
        if (dataEditarConfig.value.cliente && dataEditarConfig.value.motivo) {
          _ConfiguracionesService.value.listarColumnasTablaCrear({ cliente: dataEditarConfig.value.cliente.CardCode, motivo: dataEditarConfig.value.motivo }).then(({ data }) => {
            listadoColumnas.value = data.columnasCrear
            listadoCamposOrdenamientoTabla.value = data.columnasExistentes
          })
        }
        if (filtros.value.cliente && filtros.value.motivo) {
          _ConfiguracionesService.value.listarColumnasTablaCrear({ cliente: filtros.value.cliente.CardCode, motivo: filtros.value.motivo }).then(({ data }) => {
            listadoColumnasFiltro.value = data.columnasExistentes
          })
        }
      }
      const cambioColumna = () => {
        if (listadoCamposOrdenamientoTabla.value.some(a => a.nuevo)) {
          const index = listadoCamposOrdenamientoTabla.value.findIndex(a => a.nuevo)
          listadoCamposOrdenamientoTabla.value.splice(index, 1)
        }
        const objeto = listadoColumnas.value.find(a => a.nombre === dataCrearConfig.value.campo)
        listadoCamposOrdenamientoTabla.value.push({ Columna: objeto, nuevo: true, ordenamiento: listadoCamposOrdenamientoTabla.value.length + 1 })
      }
      const rowClass = (data) => {
        return data.Columna.nombre === dataCrearConfig.value.campo ? 'nueva-columna' : null || data.Columna.nombre === dataEditarConfig.value.campo ? 'nueva-columna' : null
      }
      const ordenarFilas = (event) => {
        let temp = 1
        const array = []
        for (const i of event.value) {
          i.ordenamiento = temp
          array.push(i)
          temp = temp + 1
        }
        listadoCamposOrdenamientoTabla.value = array
      }
      const abrirModalEditar = (dataConfig) => {
        displayModalEditar.value = true
        dataEditarConfig.value.cliente = {
          CardCode: dataConfig.Cliente.CardCode,
          CardName: dataConfig.Cliente.CardName,
          Id: dataConfig.Cliente.Id,
          name: dataConfig.Cliente.CardCode + ' - ' + dataConfig.Cliente.CardName
        }
        dataEditarConfig.value.motivo = dataConfig.MotAutoriza
        dataEditarConfig.value.guardar = dataConfig.guardar
        dataEditarConfig.value.requerido = dataConfig.IsRequired
        dataEditarConfig.value.ordenamiento = dataConfig.ordenamiento
        dataEditarConfig.value.campo = dataConfig.ColName
        dataEditarConfig.value.oculto = dataConfig.campo_oculto
        dataEditarConfig.value.actualizado = dataConfig.updatedBy
        _MotivosAutorizacionService.value.listarMotivosCliente({ ClienteId: dataConfig.Cliente.Id }).then(({ data }) => {
          listadoMotivosCrear.value = data
          obtenerColumnas()
        })
      }
      const cerrarModalEditar = () => {
        displayModalEditar.value = false
        dataEditarConfig.value = {
          cliente: '',
          motivo: '',
          campo: '',
          requerido: false,
          ordenamiento: 0,
          guardar: false,
          oculto: false,
          updatedBy: ''
        }
      }
      const editarConfig = () => {
        const array = []
        for (const i of listadoCamposOrdenamientoTabla.value) {
          array.push({ ColName: i.Columna.nombre, ordenamiento: i.ordenamiento })
        }
        const object = {
          configCrear: {
            MotAutoriza: dataEditarConfig.value.motivo,
            ColName: dataEditarConfig.value.campo,
            CardCode: dataEditarConfig.value.cliente.CardCode,
            IsRequired: dataEditarConfig.value.requerido,
            ordenamiento: dataEditarConfig.value.ordenamiento,
            guardar: dataEditarConfig.value.guardar,
            campo_oculto: dataEditarConfig.value.oculto,
            updatedBy: people.full_name
          },
          configEditar: array
        }
        _ConfiguracionesService.value.crearCofiguracion(object).then(({ data }) => {
          Swal.fire(
            'Guardado!',
            'Registro editado con exito!',
            'success'
          ).then(() => {
            filtros.value.cliente = dataEditarConfig.value.cliente
            filtros.value.motivo = dataEditarConfig.value.motivo
            cerrarModalEditar()
            obtenerConfiguraciones()
          })
        })
      }
      const eliminarLineaConfiguracion = (data) => {
        Swal.fire({
          text: `Esta seguro(a) de eliminar la columna ${data.Columna.nombre_tabla} del cliente ${data.Cliente.CardName} con el motivo de autorización ${data.MotivoAutoriza.Nombre}?`,
          icon: 'warning',
          showDenyButton: true,
          showCancelButton: true,
          confirmButtonText: 'Eliminar',
          denyButtonText: 'Cancelar'
        }).then((result) => {
          if (result.isConfirmed) {
            _ConfiguracionesService.value.eliminarCofiguracion({ Id: data.Id }).then(({ data }) => {
              if (data.error) {
                Swal.fire(
                  'Cancelado!',
                  'Ocurrio un error al eliminar el registro!',
                  'error'
                )
              } else {
                Swal.fire(
                  'Eliminado!',
                  'Registro eliminado con exito!',
                  'success'
                ).then(() => obtenerConfiguraciones())
              }
            })
          } else if (result.isDenied) {
            Swal.fire('Registro no eliminado', '', 'info')
          }
        })
      }
      const crearConfig = (type) => {
        if (!type) { displayModalCrear.value = false } else {
          const array = []
          for (const i of listadoCamposOrdenamientoTabla.value) {
            array.push({ ColName: i.Columna.nombre, ordenamiento: i.ordenamiento })
          }
          const object = {
            configCrear: {
              MotAutoriza: dataCrearConfig.value.motivo,
              ColName: dataCrearConfig.value.campo,
              CardCode: dataCrearConfig.value.cliente.CardCode,
              IsRequired: dataCrearConfig.value.requerido,
              ordenamiento: dataCrearConfig.value.ordenamiento,
              guardar: dataCrearConfig.value.guardar,
              campo_oculto: dataCrearConfig.value.oculto
            },
            configEditar: array
          }
          _ConfiguracionesService.value.crearCofiguracion(object).then(({ data }) => {
            Swal.fire(
              'Guardado!',
              'Registro creado con exito!',
              'success'
            ).then(() => {
              filtros.value.cliente = dataCrearConfig.value.cliente
              filtros.value.motivo = dataCrearConfig.value.motivo
              displayModalCrear.value = false
              obtenerConfiguraciones()
            })
          })
        }
        dataCrearConfig.value = {
          cliente: '',
          motivo: '',
          campo: '',
          requerido: false,
          ordenamiento: 0,
          guardar: false
        }
      }
      onMounted(() => {
        clientesStore.obtenerListadoClientes()
      })
      return {
        listadoConfiguraciones,
        totalRecords,
        offset,
        page,
        limit,
        onPage,
        abrirModalEditar,
        displayModalEditar,
        editarConfig,
        listadoClientes,
        cambiarCliente,
        listadoMotivosFiltros,
        listadoMotivosCrear,
        filtros,
        obtenerConfiguraciones,
        displayModalCrear,
        dataCrearConfig,
        listadoColumnas,
        obtenerColumnas,
        crearConfig,
        eliminarLineaConfiguracion,
        listadoCamposOrdenamientoTabla,
        ordenarFilas,
        rowClass,
        cambioColumna,
        dataEditarConfig,
        cerrarModalEditar,
        listadoColumnasFiltro
      }
    }
  }
</script>
<style scoped>
::v-deep(.nueva-columna) {
    background-color: rgba(236, 236, 3, 0.671) !important;
}
::v-deep(.p-dropdown .p-inputtext) {
    font-size: 0.85rem;
  }
  :global(.swal2-container) {z-index: 1000000 !important}
</style>
